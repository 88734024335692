<script>
import { clientCookies } from "@/common/mixins/mix_cookies";
import { mapGetters } from "vuex";

export default {
  name: "Testing",
  mixins: [clientCookies],
  computed: {
    ...mapGetters(["getConfigJs"]),
  },
};
</script>

<template>
  <div class="h-screen bg-red-100 flex flex-col">
    <div class="bg-blue-100 h-28">1</div>
    <div class="flex-grow bg-blue-200 overflow-auto">
      <pre>{{ getConfigJs }}</pre>
      <p v-for="i in 11" :key="i">
        {{ i }}Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        Officiis quos dignissimos nisi minima, vel ipsa vero consectetur sunt
        deserunt esse necessitatibus ipsam iusto sequi fugit facilis temporibus
        est qui neque!
      </p>
    </div>

    <div class="bg-blue-300 h-28">3</div>
  </div>
</template>

<style scoped></style>
